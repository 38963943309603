import { useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { Button, Flex, Heading, Notice, Text } from '@applyboard/crystal-ui'
import styled from '@emotion/styled'
import { useAuthManager } from '../../hooks'

type CongratulationsConfirmationCardProps = {
  newPassword: string
}

export function CongratulationsConfirmationCard(props: CongratulationsConfirmationCardProps) {
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()
  const { login, isLoggingIn } = useAuthManager()

  const [showLoginError, setShowLoginError] = useState(false)

  return (
    <Flex gap={{ xs: 10, sm: 6 }} align="center" direction="column">
      <img src="/tada-illustration.svg" alt="Congratulations" />
      <Flex gap={6} align="center" direction="column">
        <Heading level={1} variant="headlineM">
          Congratulations
        </Heading>
        <TextWrapper>
          <Text align="center">
            You’re all set! Start exploring your home dashboard and submit your first application.
            Best of luck!
          </Text>
        </TextWrapper>
      </Flex>
      {showLoginError ? (
        <Notice intent="negative">
          Something went wrong. Please click "Get started" and try again.
          <Notice.CloseButton onClick={() => setShowLoginError(false)} />
        </Notice>
      ) : null}
      <Button
        intent="primary"
        onClick={() => {
          login(
            { email: searchParams?.get('username') ?? '', password: props.newPassword },
            {
              onSuccess: () => {
                navigate('/onboarding', { replace: true })
              },
              onError: () => {
                setShowLoginError(true)
              },
            },
          )
        }}
        loading={isLoggingIn}
      >
        Get started
      </Button>
    </Flex>
  )
}

const TextWrapper = styled.div(() => ({
  maxWidth: 435,
}))
