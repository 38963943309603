import {
  Button,
  Combobox,
  ComboboxOptionProps,
  createForm,
  Flex,
  Heading,
  Select,
  Text,
} from '@applyboard/crystal-ui'
import { ArrowRightOutlineIcon } from '@applyboard/ui-icons'
import styled from '@emotion/styled'
import { compact } from 'lodash'

import { useGetIntakeTerms, useGetProgramIntakes } from '../../../hooks'
import { useGetPrograms } from '../../../hooks/useGetPrograms'
import { StudentProgram } from '@backend/resources/program/types'
import { Loading } from '../../../components/Loading'
import { StudentApplication } from '../../../components/Application/types'

type SelectProgramFormFields = {
  program: string
  intake: string
}

type SelectProgramProps = {
  application?: StudentApplication | null
  currentStep: number
  onSubmit: (data: { program?: string; intake?: string }) => void
  isLoading?: boolean
}

const { Form, Field, useFieldValues, useSetFieldValues } = createForm<SelectProgramFormFields>()

export function SelectProgram(props: SelectProgramProps) {
  return (
    <Form
      //validationMode="onSubmit"  TODO: Requires Crystal package update
      defaultValues={{
        program: props.application?.attributes?.programSelected?.program?.id || '',
        intake: props.application?.attributes?.programSelected?.programIntakeId || '',
      }}
      onSubmit={({ program, intake }) => {
        props.onSubmit({ program, intake })
      }}
    >
      <SelectProgramFields currentStep={props.currentStep} isLoading={props.isLoading} />
    </Form>
  )
}

type SelectProgramFieldsProps = Omit<SelectProgramProps, 'onSubmit'>

function SelectProgramFields(props: SelectProgramFieldsProps) {
  const { program: selectedProgram } = useFieldValues(['program', 'intake'])
  const setFieldValues = useSetFieldValues()

  const { programs, isLoadingPrograms } = useGetPrograms()
  const { programIntakes, isFetchingProgramIntakes } = useGetProgramIntakes({
    programId: selectedProgram,
  })
  const { intakeTerms, isFetchingIntakeTerms } = useGetIntakeTerms()

  const programsData: StudentProgram[] = programs?.map(pgm => pgm.attributes) || []

  if (isLoadingPrograms) {
    return <Loading />
  }

  const isLoading = props.isLoading || isFetchingIntakeTerms || isFetchingProgramIntakes

  return (
    <Flex direction="column" gap={12}>
      <Flex justify="center">
        <Illustration src="/program-selection-illustration.svg" alt="Program Selection" />
      </Flex>
      <Flex gap={6} direction="column">
        <Heading level={1} align="center" variant="headlineL">
          Program Selection
        </Heading>
        <Text align={{ xs: 'start', sm: 'center' }} variant="bodyL">
          Let’s begin by finding a program and an intake you would like to apply to.
        </Text>
      </Flex>
      <Flex gap={6} direction="column">
        <ComboBoxWrapper>
          <Field
            as={Combobox}
            label="Program"
            required="Program is required"
            placeholder="Search"
            type="search"
            name="program"
            size="md"
            onChange={val => {
              setFieldValues({
                intake: '',
              })
            }}
            filterOption={(option: ComboboxOptionProps, query: string) => {
              return option.label.toLowerCase().includes(query.toLowerCase())
            }}
          >
            {programsData?.map(program => (
              <Combobox.Option key={program.id} label={program.name} value={program.id} />
            ))}
          </Field>
        </ComboBoxWrapper>
        {selectedProgram ? (
          <Field
            as={Select}
            label="Intake"
            placeholder="Select"
            name="intake"
            required="Intake is required"
            appearance="styled"
          >
            {compact(
              intakeTerms?.data?.map(t => {
                const programIntake = programIntakes?.data?.find(
                  p => p.relationships.intakeTerm.data.id === t.id,
                )
                if (!programIntake) return undefined
                return {
                  id: programIntake.id,
                  startDate: t.attributes.name,
                }
              }) || [],
            ).map(intake => (
              <Select.Option key={intake.id} label={intake.startDate} value={intake.id} />
            ))}
          </Field>
        ) : null}
      </Flex>
      <Flex justify="end" pt={8} hideBelow="sm">
        <Button
          type="submit"
          intent="primary"
          trailIcon={ArrowRightOutlineIcon}
          loading={isLoading}
        >
          Save & Continue
        </Button>
      </Flex>
      <Flex justify="end" hideAbove="sm">
        <Button type="submit" intent="primary" loading={isLoading} width="fill">
          Save & Continue
        </Button>
      </Flex>
    </Flex>
  )
}

const Illustration = styled.img({
  maxWidth: 160,
  width: '100%',
})

const ComboBoxWrapper = styled.div`
  b {
    font-weight: normal !important;
  }
`
