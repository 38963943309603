import { Button, MenuButton } from '@applyboard/crystal-ui'
import { DeleteFillIcon, MoreVertOutlineIcon, EditFillIcon } from '@applyboard/ui-icons'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { DeleteApplicationDialog } from '../../../components/Application'
import { RefinedVersionedApplicationResourceWithFiles } from 'applications-types-lib'

type DropdownMenuProps = {
  application: RefinedVersionedApplicationResourceWithFiles
  onEditProgram: () => void
}

export function DropdownMenu(props: DropdownMenuProps) {
  const [openMenuButton, setOpenMenuButton] = useState(false)
  const [openDiscardDraftDialog, setOpenDiscardDraftDialog] = useState(false)
  const navigate = useNavigate()

  return (
    <>
      <MenuButton open={openMenuButton} onOpenChange={setOpenMenuButton} alignment="right">
        <MenuButton.Trigger>
          <Button
            aria-label="Open menu"
            leadIcon={MoreVertOutlineIcon}
            onClick={() => setOpenMenuButton(open => !open)}
            emphasis="highlighted"
          />
        </MenuButton.Trigger>
        <MenuButton.Button
          id="modify-program"
          icon={EditFillIcon}
          onClick={() => props.onEditProgram()}
        >
          Edit Program Selection
        </MenuButton.Button>
        <MenuButton.Button
          id="discard-draft"
          icon={DeleteFillIcon}
          onClick={() => setOpenDiscardDraftDialog(true)}
        >
          Discard Draft
        </MenuButton.Button>
      </MenuButton>

      <DeleteApplicationDialog
        open={openDiscardDraftDialog}
        onOpenChange={setOpenDiscardDraftDialog}
        applicationId={props.application.id}
        onSuccess={() => {
          navigate('/', { replace: true, state: props.application })
        }}
      />
    </>
  )
}
