import {
  Button,
  ButtonLink,
  createForm,
  Flex,
  Heading,
  Notice,
  Text,
  TextInput,
} from '@applyboard/crystal-ui'
import { useForgotPassword } from '../../hooks'
import { OnBoardingBox } from '../../components/OnBoardingBox'
import { ArrowLeftOutlineIcon } from '@applyboard/ui-icons'
import { emailValidate } from '../../utils/fieldValidations'
import { useState } from 'react'
import styled from '@emotion/styled'
import { useSearchParams } from 'react-router-dom'

const { Form, Field, useFieldValues } = createForm<{ email: string }>()

export function ForgotPasswordPage() {
  const [searchParams] = useSearchParams()
  const expired = searchParams.get('expired')

  const [success, setSuccess] = useState<boolean>(false)
  const [error, setError] = useState<string>(
    Boolean(expired) ? 'Link is not valid or has been expired' : '',
  )
  const { forgotPassword, isLoadingForgotPassword } = useForgotPassword()

  return (
    <OnBoardingBox>
      <Form
        defaultValues={{
          email: '',
        }}
        onSubmit={({ email }) => {
          forgotPassword(
            {
              email,
            },
            {
              onSuccess: () => {
                setError('')
                setSuccess(true)
              },
              onError: err => {
                if (err instanceof Error) {
                  setError(err.message)
                }
              },
            },
          )
        }}
      >
        {success ? (
          <ForgotPasswordLinkSentPage />
        ) : (
          <Flex gap={10} direction="column">
            <Flex align="center" wrap={true} gap={10}>
              <ButtonLink
                href="/login"
                intent="secondary"
                emphasis="highlighted"
                leadIcon={ArrowLeftOutlineIcon}
                aria-label="back"
                mr={4}
              />
              <Heading level={1} variant="headlineS">
                Forgot your password
              </Heading>
            </Flex>
            <Flex gap={6} direction="column">
              {error ? (
                <Notice intent="negative">
                  {error}
                  <Notice.CloseButton onClick={() => setError('')} />
                </Notice>
              ) : null}
              <Text variant="bodyL">
                Enter the email associated with your account and we’ll send you an email with a link
                to reset your password.
              </Text>
              <Field
                as={TextInput}
                name="email"
                label="Email"
                required="Email is required"
                validate={(value: string) => {
                  return emailValidate(value) || 'This email is invalid.'
                }}
              />
            </Flex>
            <ForgotPasswordSubmitButton loading={isLoadingForgotPassword} />
          </Flex>
        )}
      </Form>
    </OnBoardingBox>
  )
}

type ForgotPasswordSubmitButtonProps = {
  loading: boolean
}

function ForgotPasswordSubmitButton(props: ForgotPasswordSubmitButtonProps) {
  const { email } = useFieldValues(['email'])

  return (
    <Button
      intent="primary"
      loading={props.loading}
      type="submit"
      width="fill"
      disabled={!emailValidate(email)}
    >
      Send link
    </Button>
  )
}

function ForgotPasswordLinkSentPage() {
  const [error, setError] = useState<string>('')
  const { email } = useFieldValues(['email'])
  const { forgotPassword, isLoadingForgotPassword } = useForgotPassword()

  return (
    <Flex direction="column" gap={10}>
      <Flex direction="column" align="center" gap={6}>
        <img src="/email-illustration.svg" alt="Email illustration" />
        <Heading level={1} variant="headlineM">
          Check your email
        </Heading>
        {error ? (
          <Notice intent="negative">
            {error}
            <Notice.CloseButton onClick={() => setError('')} />
          </Notice>
        ) : null}
        <TextWrapper>
          <Text align="center">
            We have sent a password reset link to your email with instructions. You might need to
            check your spam folder if you don’t see it.
          </Text>
        </TextWrapper>
      </Flex>
      <Button
        intent="primary"
        variant="outlined"
        width="fill"
        onClick={() => {
          forgotPassword(
            {
              email,
            },
            {
              onSuccess: () => {
                setError('')
              },
              onError: err => {
                if (err instanceof Error) {
                  setError(err.message)
                }
              },
            },
          )
        }}
        loading={isLoadingForgotPassword}
      >
        Resend link
      </Button>
    </Flex>
  )
}

const TextWrapper = styled.div(() => ({
  maxWidth: 505,
}))
