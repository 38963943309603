import { GetProgramsResponse } from '@backend/students-api/routes/programs/GET/types'
import { ApiStudentRequest, request } from '../utils/request'
import { StudentProgram, StudentProgramResource } from '@backend/resources/program/types'
import { useAuthInfiniteQuery } from './auth'

export type Program = StudentProgram

export function useGetPrograms() {
  const { isLoading, data } = useAuthInfiniteQuery<
    StudentProgramResource,
    GetProgramsResponse,
    string[]
  >(
    ['pgm-list'],
    async context => {
      return await request<GetProgramsResponse>(
        new ApiStudentRequest(`/programs?page[size]=300&page[number]=${context.pageParam || 1}`, {
          method: 'GET',
        }),
        { isExpectedResponse: (res): res is GetProgramsResponse => res },
      )
    },
    {
      refetchOnWindowFocus: false,
    },
  )

  return {
    isLoadingPrograms: isLoading,
    programs: data,
  }
}
