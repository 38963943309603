import { useMutation } from '@tanstack/react-query'
import { ApiStudentRequest, request } from '../utils/request'
import { NewProfileResource, VersionedProfileResource } from '@backend/resources/profile'

type SignUpParams = NewProfileResource['attributes']

type RawProfilesResponse = {
  data: VersionedProfileResource
}

export function useSignUp() {
  const { isPending, mutate } = useMutation({
    mutationFn: async (params: SignUpParams) => {
      return await request<RawProfilesResponse>(
        new ApiStudentRequest('/profiles', {
          method: 'POST',
          body: {
            data: {
              type: 'student_profile',
              attributes: {
                givenName: params.givenName,
                familyName: params.familyName,
                email: params.email,
              },
            },
          },
        }),
        {
          isExpectedResponse: (res): res is RawProfilesResponse => res,
        },
      )
    },
  })

  return {
    isSigningUp: isPending,
    signUp: mutate,
  }
}
