import { useAuthQuery } from './auth'
import { ApiStudentRequest, request } from '../utils'
import { GetIntakeTermsResponse } from '@backend/students-api/routes/intake-terms/GET/types'

type GetIntakeTermsProps = {
  pageNumber?: number
  pageSize?: number
  requestCachingTag?: string | undefined
}

export function useGetIntakeTerms(props?: GetIntakeTermsProps) {
  const selectedPageNumber = props?.pageNumber || 1
  const selectedPageSize = props?.pageSize || 300
  const { isLoading, isFetching, data } = useAuthQuery(
    ['intake-terms-list', selectedPageNumber, selectedPageSize, props?.requestCachingTag],
    async context => {
      return await request<GetIntakeTermsResponse>(
        new ApiStudentRequest(
          `/intake-terms?page[size]=${selectedPageSize}&page[number]=${selectedPageNumber}`,
          {
            method: 'GET',
          },
        ),
        {
          isExpectedResponse: (res): res is GetIntakeTermsResponse => res,
        },
      )
    },
    {
      refetchOnWindowFocus: false,
    },
  )

  return {
    isLoadingIntakeTerms: isLoading,
    isFetchingIntakeTerms: isFetching,
    intakeTerms: data,
  }
}
