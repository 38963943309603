import { SearchResponse } from '@backend/resources/types'
import {
  QueryFunction,
  QueryFunctionContext,
  QueryKey,
  UseInfiniteQueryResult,
  useInfiniteQuery,
  useQueryClient,
  UseInfiniteQueryOptions,
} from '@tanstack/react-query'
import { flatten } from 'lodash'
import { ResourceObject } from 'ts-json-api'
import { useAuthManager } from './useAuthManager'
import { useAuthToken } from './useAuthToken'
import { GenericError } from '../../utils'
import { Optional } from '@backend/utils/types'

export function useAuthInfiniteQuery<
  TResource extends ResourceObject,
  TQueryFnData extends SearchResponse<TResource>,
  TQueryKey extends QueryKey = QueryKey,
>(
  queryKey: TQueryKey,
  queryFn: QueryFunction<TQueryFnData, TQueryKey, number>,
  queryOptions?: Optional<
    Omit<
      UseInfiniteQueryOptions<
        TQueryFnData,
        GenericError,
        TQueryFnData,
        TQueryFnData,
        TQueryKey,
        number
      >,
      'queryKey' | 'queryFn' | 'select'
    >,
    'getNextPageParam' | 'initialPageParam'
  >,
): UseInfiniteQueryResult<TResource[], Error> {
  const queryClient = useQueryClient()
  const { isActiveSession } = useAuthToken()
  const { isRefreshingAccessToken, refreshAccessToken, signout } = useAuthManager()

  const useQueryResponse: UseInfiniteQueryResult<TResource[], Error> = useInfiniteQuery({
    getNextPageParam: (lastPage, _allPages) => {
      return lastPage.meta.page + 1
    },
    initialPageParam: 1,
    queryFn: async (context: QueryFunctionContext<TQueryKey, number>) => {
      try {
        if (!isActiveSession()) await refreshAccessToken({ throwOnError: true })
      } catch (e) {
        queryClient.invalidateQueries()
        signout()
        throw new Error('Unable to refresh access token')
      }

      return await queryFn(context)
    },
    queryKey,
    enabled: !isRefreshingAccessToken,
    ...queryOptions,
    select: data => flatten(data.pages.map(page => page.data)),
  })

  return useQueryResponse
}
