import { Button } from '@applyboard/crystal-ui'
import { ApplicationResource } from 'applications-types-lib'
import { getBaseUrl } from '../../utils/request/getBaseUrl'
import { useContext, useEffect } from 'react'
import { InitContext } from '../../config/initializers'

declare global {
  interface Window {
    flywire?: any
  }
}

export type FlywireButtonProps = {
  application: ApplicationResource
  onClick: () => void
}

export function FlywireButton(props: FlywireButtonProps): JSX.Element {
  const context = useContext(InitContext)

  useEffect(() => {
    const { application } = props

    const schoolId = application.relationships.school.data.id
    const appId = application.id
    const baseUrl = getBaseUrl()

    const config = {
      ...context.flywireConfig,

      // Pre-fill form values
      amount: application.attributes?.applicationFee?.amount,
      sender_country:
        application.attributes?.personalInformation?.addresses?.residentialAddress?.country,
      sender_phone: application.attributes?.personalInformation?.contactInformation?.phoneNumber,
      sender_first_name:
        application.attributes?.personalInformation?.basicPersonalInformation?.givenName,
      sender_middle_name:
        application.attributes?.personalInformation?.basicPersonalInformation?.middleName,
      sender_last_name:
        application.attributes?.personalInformation?.basicPersonalInformation?.familyName,
      sender_address1:
        application.attributes?.personalInformation?.addresses?.residentialAddress?.addressLine1,
      sender_city: application.attributes?.personalInformation?.addresses?.residentialAddress?.city,
      sender_email: application.attributes?.personalInformation?.contactInformation?.email,

      // Callback values
      student_first_name:
        application.attributes?.personalInformation?.basicPersonalInformation?.givenName,
      student_last_name:
        application.attributes?.personalInformation?.basicPersonalInformation?.familyName,
      student_email: application.attributes?.personalInformation?.contactInformation?.email,
      student_date_of_birth: '',

      // Callback configuration
      return_url: baseUrl,
      callback_id: `${schoolId}#${appId}`,
      callback_version: '2',
      callback_url: `${baseUrl}/api/payments`,
    }

    window.flywire.Checkout.render(config, '#checkout-button')
  })

  return (
    <Button width="fill" intent="primary" id="checkout-button" onClick={props.onClick}>
      Pay and submit
    </Button>
  )
}
