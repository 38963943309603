import { useAuthQuery } from './auth'
import { ApiStudentRequest, request } from '../utils'
import { GetProgramIntakesResponse } from '@backend/students-api/routes/programs/{id}/program-intakes/GET/types'

type GetProgramIntakesProps = {
  programId?: string
  pageNumber?: number
  pageSize?: number
  requestCachingTag?: string | undefined
}

export function useGetProgramIntakes(props: GetProgramIntakesProps) {
  const selectedPageNumber = props?.pageNumber || 1
  const selectedPageSize = props?.pageSize || 300
  const { isLoading, isFetching, data } = useAuthQuery(
    ['program-intakes-list', props.programId],
    async context => {
      return await request<GetProgramIntakesResponse>(
        new ApiStudentRequest(
          `/programs/${props.programId}/program-intakes?page[size]=${selectedPageSize}&page[number]=${selectedPageNumber}`,
          {
            method: 'GET',
          },
        ),
        {
          isExpectedResponse: (res): res is GetProgramIntakesResponse => res,
        },
      )
    },
    {
      enabled: !!props.programId,
      refetchOnWindowFocus: false,
    },
  )

  return {
    isLoadingProgramIntakes: isLoading,
    isFetchingProgramIntakes: isFetching,
    programIntakes: data,
  }
}
