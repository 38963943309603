import { useState } from 'react'
import {
  Box,
  Button,
  Combobox,
  createForm,
  DatePicker,
  Flex,
  Notice,
  Heading,
  Select,
  TextInput,
} from '@applyboard/crystal-ui'
import { COUNTRY_NAMES } from 'schools-domain-backend-utils/dist/common-types/country'
import { GENDER_NAMES } from 'schools-domain-backend-utils/dist/common-types/gender'
import { useGetProfile, useLoggedInUser, useUpdateProfile } from '../../hooks'
import { Loading } from '../../components/Loading'
import { NavBars } from '../../components/NavBars'
import { convertTimelessDateStrToLocalDate } from '../../utils/convertTimelessDateStrToLocalDate'

type ProfileFormFields = {
  givenName: string
  familyName: string
  middleName: string
  dateOfBirth: string
  nationality: string
  gender: string
  email: string
}

const { Form, Field } = createForm<ProfileFormFields>()

export function ProfilePage() {
  const { username } = useLoggedInUser()
  const { isLoadingProfile, profile } = useGetProfile({ id: username })
  const { isUpdatingProfile, updateProfile } = useUpdateProfile({ id: username })

  const [profileSaveNotice, setProfileSaveNotice] = useState({ intent: '', actionText: '' })

  if (isLoadingProfile) {
    return (
      <NavBars>
        <Loading />
      </NavBars>
    )
  }

  if (!profile) {
    return null
  }

  return (
    <NavBars>
      <Form
        defaultValues={{
          givenName: profile.attributes.givenName,
          familyName: profile.attributes.familyName || '',
          middleName: profile.attributes.middleName || '',
          dateOfBirth: profile.attributes.dateOfBirth
            ? convertTimelessDateStrToLocalDate(profile.attributes.dateOfBirth).toISOString()
            : '',
          nationality: profile.attributes.nationality || '',
          gender: profile.attributes.gender || '',
          email: profile.attributes.email || '',
        }}
        onSubmit={data => {
          updateProfile(data, {
            onSuccess: () => {
              setProfileSaveNotice({
                intent: 'positive',
                actionText: 'Changes to your profile successfully saved.',
              })
            },
            onError: err => {
              if (err instanceof Error) {
                setProfileSaveNotice({ intent: 'negative', actionText: err.message })
              } else {
                setProfileSaveNotice({
                  intent: 'negative',
                  actionText: 'Something went wrong when saving your profile.',
                })
              }
            },
          })
        }}
      >
        <ProfilePageForm
          loading={isUpdatingProfile}
          profileSaveNotice={profileSaveNotice}
          clearProfileSaveNotice={() => setProfileSaveNotice({ intent: '', actionText: '' })}
        />
      </Form>
    </NavBars>
  )
}

type ProfilePageFormProps = {
  loading: boolean
  profileSaveNotice: { intent: string; actionText: string }
  clearProfileSaveNotice: () => void
}

function ProfilePageForm(props: ProfilePageFormProps) {
  const maxDate = new Date()
  const minDate = new Date(1900, 0, 1)

  return (
    <>
      <Flex align="center" justify="between" pb={4}>
        <Flex gap={4} align="center">
          <img src="/profile-illustration.svg" alt="" />
          <Heading level={1} variant="headlineL">
            My Profile
          </Heading>
        </Flex>
        <Flex hideBelow="sm">
          <Button intent="primary" type="submit" loading={props.loading}>
            Save
          </Button>
        </Flex>
      </Flex>
      <ProfileSaveNotice
        intent={props.profileSaveNotice.intent}
        actionText={props.profileSaveNotice.actionText}
        onClose={props.clearProfileSaveNotice}
      />
      <Flex gap={6} pb={{ xs: 6, sm: 10 }} direction={{ xs: 'column', sm: 'row' }}>
        <Flex.Item basis={{ xs: '100%', sm: 'calc(33% - 12px)' }}>
          <Field
            as={TextInput}
            label="Given Name"
            name="givenName"
            validate={givenName => {
              if (!givenName) {
                return 'This field is required.'
              }

              return true
            }}
          />
        </Flex.Item>
        <Flex.Item basis={{ xs: '100%', sm: 'calc(33% - 12px)' }}>
          <Field as={TextInput} label="Family Name" name="familyName" />
        </Flex.Item>
        <Flex.Item basis={{ xs: '100%', sm: 'calc(33% - 12px)' }}>
          <Field as={TextInput} label="Middle Name (optional)" name="middleName" />
        </Flex.Item>
      </Flex>
      <Flex gap={6} pb={{ xs: 6, sm: 10 }} direction={{ xs: 'column', sm: 'row' }}>
        <Flex.Item basis={{ xs: '100%', sm: 'calc(33% - 12px)' }}>
          <Field
            as={DatePicker}
            label="Date of Birth"
            name="dateOfBirth"
            maxDate={maxDate.toISOString()}
            minDate={minDate.toISOString()}
          />
        </Flex.Item>
        <Flex.Item basis={{ xs: '100%', sm: 'calc(33% - 12px)' }}>
          <Field
            as={Combobox}
            label="Nationality"
            name="nationality"
            size="md"
            placeholder="Select"
          >
            {Object.entries(COUNTRY_NAMES).map(([countryCode, countryName]) => (
              <Combobox.Option key={countryCode} label={countryName} value={countryCode} />
            ))}
          </Field>
        </Flex.Item>
        <Flex.Item basis={{ xs: '100%', sm: 'calc(33% - 12px)' }}>
          <Field as={Select} label="Gender" name="gender" appearance="styled">
            {Object.entries(GENDER_NAMES).map(([genderCode, genderName]) => (
              <Select.Option key={genderCode} label={genderName} value={genderCode} />
            ))}
          </Field>
        </Flex.Item>
      </Flex>
      <Flex gap={6} pb={{ xs: 6, sm: 10 }} direction={{ xs: 'column', sm: 'row' }}>
        <Flex.Item basis={{ xs: '100%', sm: 'calc(33% - 12px)' }}>
          <Field as={TextInput} label="Email" name="email" disabled />
        </Flex.Item>
      </Flex>
      <Flex hideAbove="sm">
        <Button intent="primary" type="submit" width="fill" loading={props.loading}>
          Save
        </Button>
      </Flex>
    </>
  )
}

function ProfileSaveNotice({
  actionText,
  intent,
  onClose,
}: {
  actionText: string
  intent: string
  onClose: () => void
}) {
  let noticeComponent = null
  if (intent === 'positive' || intent === 'negative') {
    noticeComponent = (
      <Notice intent={intent}>
        {actionText}
        <Notice.CloseButton onClick={() => onClose()} />
      </Notice>
    )
  }

  return <Box pb={{ xs: 4, sm: !!noticeComponent ? 4 : 20 }}>{noticeComponent}</Box>
}
