import { Card, DescriptionList, Flex } from '@applyboard/crystal-ui'
import { ReviewRequirementsButton } from './ReviewRequirementsButton'
import { hasApplicationFee } from '../../../utils'
import { StudentApplication } from '../types'

type MobileHeaderProps = {
  application: StudentApplication
}

export function MobileHeader(props: MobileHeaderProps) {
  const program = props.application?.attributes?.programSelected

  return (
    <Card emphasis="highlighted">
      <Flex direction="column" gap={4}>
        <DescriptionList aria-label="Application Details" variant="grid">
          <DescriptionList.Item
            align="start"
            justify="start"
            details={program?.program?.name}
            term="Program name"
          />
          <DescriptionList.Item
            align="start"
            justify="start"
            details={program?.programIntakeTerm?.name}
            term="Intake term"
          />
          <DescriptionList.Item
            align="start"
            justify="start"
            details={program?.campus?.name ?? '--'}
            term="Campus"
          />
          <DescriptionList.Item
            align="start"
            justify="start"
            details={props.application?.attributes?.humanReadableId ?? '--'}
            term="Application ID"
          />
          <DescriptionList.Item
            align="start"
            justify="start"
            details={
              hasApplicationFee(props.application)
                ? Intl.NumberFormat(undefined, {
                    style: 'currency',
                    currency: props.application?.attributes?.applicationFee?.currency || 'CAD',
                  }).format(props.application?.attributes?.applicationFee?.amount || 0)
                : 'No Fee'
            }
            term="Application fee"
          />
        </DescriptionList>
        <Card.Divider />
        <Flex justify="center">
          <ReviewRequirementsButton programId={program?.program?.id as string} />
        </Flex>
      </Flex>
    </Card>
  )
}
